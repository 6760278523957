import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { DropzoneArea } from "material-ui-dropzone";
import { UploadimageAction } from "../../../store/actions/DeviceActions";
import { useState } from "react";

function App() {
  const [data, setData] = React.useState("");
  const [dropzoneKey, setDropzoneKey] = React.useState(0);
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (files) => {
    console.log(files);
    setData(files);
  };

  const handletransfer = () => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("imei_image", data[0]);
    console.log(data[0], formData);
    dispatch(UploadimageAction(formData)).then((res) => {
      setBtnLoading(false);
    });
    setDropzoneKey(dropzoneKey + 1);
  };
  return (
    <>
      <DropzoneArea
        key={"dropzone-" + dropzoneKey}
        filesLimit={1}
        acceptedFiles={["image/*"]}
        onChange={handleChange}
        maxFileSize={50000000}
      />
      {data.length !== 0 && (
        <Button
          onClick={handletransfer}
          type="submit"
          loading={btnLoading}
          className="btn btn-primary mt-5"
        >
          Upload Image
        </Button>
      )}
    </>
  );
}

export default App;
