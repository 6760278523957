import { Table, Button } from "antd";
import { useEffect, useState } from "react";
import { get_announcement } from "../../../store/actions/DeviceActions";

import { useDispatch } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import "antd/dist/antd.min.css";

const Announcement = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const dispatch = useDispatch();

  const columns = [
    // {
    //   title: "S#No",
    //   dataIndex: "id",
    //   key: "id",
    // },
    // {
    //   title: "Send To",
    //   dataIndex: "send_to_text",
    //   key: "send_to_text",
    // },
    {
      title: "Message",
      dataIndex: "message_text",
      key: "message_text",
    },
    {
      title: "File",
      dataIndex: "documents",
      key: "documents",
      width: 200,
      render: (record) => (
        <span>
          {record.map((item, index) => {
            return (
              <a
                key={index}
                href={item.aws_document_url}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DownloadOutlined />}
                />
              </a>
            );
          })}
        </span>
      ),
    },
    // {
    //   title: "Created By",
    //   dataIndex: "created_by_name",
    //   key: "created_by_name",
    // },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];
  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = () => {
    setLoading(true);
    dispatch(get_announcement(getRandomuserParams(tableParams)))
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.items.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: res.data.items.total,
            },
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Announcement{" "}
                {tableParams.pagination.total &&
                  `(${tableParams.pagination.total})`}
              </h4>
            </div>
            <div className="card-body">
              <Table
                scroll={{ x: true }}
                columns={columns}
                rowKey={(record) => record.key}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Announcement;
