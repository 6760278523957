import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getPostsAction } from "../../../store/actions/PostActions";
import { getDeviceAction } from "../../../store/actions/DeviceActions";
import RequestDatatable from "./RequestTable";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { forcepassword, logout } from "../../../store/actions/AuthActions";
import { Link } from "react-router-dom";
import { Spin } from "antd";

const Dashboard = (props) => {
  const { user } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setloading] = useState(true);
  const [TBloading, setTBloading] = useState(true);


  let errorsObj = { email: "" };
  const [errors, setErrors] = useState(errorsObj);
  useEffect(() => {
    dispatch(getPostsAction())
      .then((res) => {
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
    dispatch(getDeviceAction())
    .then((res) => {
      setTBloading(false);
      // setloading(false);
    })
    .catch((err) => {
      setTBloading(false);
      // setloading(false);
    });
    // const interval = setInterval(() => {
    //   dispatch(getPostsAction());
    //   dispatch(getDeviceAction());
    // }, 5000);
    // return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    console.log(user);
    if (user.password_changed === 0) {
      setOpen(true);
    }
  }, [user]);

  console.log(props);
  const handleClose = (event, reason) => {
    //console.log(event,reason)
    if (reason && reason === "backdropClick") return;
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const onSubmit = (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (newpass !== oldpass) {
      console.log(oldpass);
      errorObj.email = "Password Mismatch";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    console.log(oldpass, newpass, user.idToken);
    dispatch(forcepassword(newpass, user.idToken))
      .then((res) => {
        console.log(res);
        setOpen(false);
        dispatch(logout(props.history));
      })
      .catch((err) => {
        console.log(err);
      });
    // history.push("/login");
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="row">
         
          <div className="col-xl-3 col-sm-6">
            <Link to="/Inventory">
              <div className="card gradient-2 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">{props.dashdata.In_stock}</h2>
                    <span className="fs-18">In Stock</span>
                  </div>
                  <i
                    style={{ color: "#fff" }}
                    className="fa fa-5x fa-cubes"
                  ></i>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-xl-3 col-sm-6">
            <div className="card gradient-1 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{props.dashdata.Assigned}</h2>
                  <span className="fs-18">Agent Pending</span>
                </div>
                <i style={{ color: "#fff" }} className="fa fa-5x fa-cube"></i>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-sm-6">
            <div className="card gradient-3 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">
                    {props.dashdata.Transfer_device}
                  </h2>
                  <span className="fs-18">Transfer</span>
                </div>
                <i
                  style={{ color: "#fff" }}
                  className="fa fa-5x fa-exchange-alt"
                ></i>
              </div>
            </div>
          </div>

          {/* <div className="col-xl-4 col-sm-6">
          <div className="card gradient-4 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{props.dashdata.sold_count}</h2>
                <span className="fs-18">Sold</span>
              </div>
              <i
                style={{ color: "#fff" }}
                className="fa fa-5x fa-mobile-alt"
              ></i>
            </div>
          </div>
        </div> */}

          <div className="col-xl-3 col-sm-6">
            <Link to="/allrmareport">
              <div className="card gradient-5 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">{props.dashdata.RMA}</h2>
                    <span className="fs-18">RMA</span>
                  </div>
                  <i
                    style={{ color: "#fff" }}
                    className="fa fa-5x fa-mobile-alt"
                  ></i>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-xl-3 col-sm-6">
            <Link to="/allrmareport">
              <div className="card gradient-5 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">{props.dashdata.pending_rma}</h2>
                    <span className="fs-18">Pending RMA</span>
                  </div>
                  <i
                    style={{ color: "#fff" }}
                    className="fa fa-5x fa-mobile-alt"
                  ></i>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-xl-3 col-sm-6">
            {/* <Link to=""> */}
              <div className="card gradient-5 card-bx">
                <div className="card-body d-flex align-items-center">
                  <div className="me-auto text-white">
                    <h2 className="text-white">{props.dashdata.device_swap}</h2>
                    <span className="fs-18">Device Swap</span>
                  </div>
                  <i
                    style={{ color: "#fff" }}
                    className="fa fa-5x fa-mobile-alt"
                  ></i>
                </div>
              </div>
            {/* </Link> */}
          </div>
        </div>
      </Spin>
      <RequestDatatable  loading={TBloading} data={props.device} />
      <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your new password below.
          </DialogContentText>
          <form onSubmit={(e) => onSubmit(e)}>
            <label className="text-label mt-4">New Password *</label>
            <div className="input-group transparent-append mb-2">
              <input
                required
                type={`${showPassword1 ? "text" : "password"}`}
                className="form-control"
                placeholder="Type Your Password"
                onChange={(e) => setOldpass(e.target.value)}
              />
              <div
                className="input-group-text"
                onClick={() => setShowPassword1(!showPassword1)}
              >
                <i
                  className={`${
                    showPassword1 ? "fa fa-eye" : "fa fa-eye-slash"
                  }`}
                />
              </div>
            </div>
            <label className="text-label">Confirm Password *</label>
            <div className="input-group transparent-append mb-2">
              <input
                required
                type={`${showPassword2 ? "text" : "password"}`}
                className="form-control"
                placeholder="Type Your Password"
                onChange={(e) => setNewpass(e.target.value)}
              />
              <div
                className="input-group-text"
                onClick={() => setShowPassword2(!showPassword2)}
              >
                <i
                  className={`${
                    showPassword2 ? "fa fa-eye" : "fa fa-eye-slash"
                  }`}
                />
              </div>
              <div
                id="val-username1-error"
                className="invalid-feedback animated fadeInUp"
                style={{ display: "block" }}
              >
                {errors.email && (
                  <div className="text-danger fs-12">{errors.email}</div>
                )}
              </div>
            </div>

            <div className="text-center mt-5">
              <button type="submit" className="btn btn-primary btn-block">
                SUBMIT
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.auth,
    dashdata: state.posts.posts,
    device: state.devicelist,
  };
};

export default connect(mapStateToProps)(Dashboard);
