import axiosInstance from "../services/AxiosInstance";
import axiosRootInstance from "../services/AxiosRootInstance";
import swal from "sweetalert";

export function getDevice() {
  return axiosInstance.get("/agentassignlist");
}

export function getTransaction() {
  return axiosInstance.get("/agentnotification");
}

export function acceptDevice(postData) {
  return axiosInstance.post("/accept", postData);
}

export function transferDevice(postData) {
  return axiosInstance.post("/transfer_device", postData);
}

export function scanDevice(postData) {
  return axiosInstance.post("/agentscandevices", postData);
}

export function pickupDevice(postData) {
  return axiosInstance.post("/agentpickup", postData);
}

export function inventoryDevice(postData) {
  return axiosInstance.get("/agentinventorylistv1", postData);
}

export function documentDevice(postData) {
  return axiosInstance.get("/get_documents", postData);
}

export function RmaReportDevice(params) {
  return axiosInstance.get(
    //"/get_rma_device_list"
    `/get_rma_device_list?keyword=${params.search_text ?? ""}&status=${
        params.status ?? ""
      }`
    // &status=${
    //   params.status ?? ""
    // }&reason=${params.reason ?? ""}& reason_two=${params.reason_two ?? ""}`
  );
}

export function exportRmaReportDevice(params) {
  return axiosRootInstance.get(
    `/api/v1/agent/agent_rma_request_status_export?keyword=${params ?? ""}&status=${params ?? ""}`
  );
}

export function RmaDeviceStatus() {
  return axiosRootInstance.get("/api/v1/reference/rma_device_status");
}

export function documentPostDevice(postData) {
  return axiosInstance.post("/save_document_data", postData);
}

export function rmaReasons() {
  return axiosRootInstance.get("/api/v1/reference/rma_reasons");
}

export function imageDevice() {
  return axiosInstance.post("/device_image");
}

export function RMAimageDevice(postData) {
  return axiosInstance.post("/upload_rma_device", postData);
}

export function RMADeviceSend(postData) {
  return axiosInstance.post("/rma_device_request", postData);
}

export function RMADeviceSend_admin(postData) {
  return axiosInstance.post("/rma_device_request_v2", postData);
}

export function rmaReasons_agent() {
  return axiosRootInstance.get("/api/v1/reference/rma_reasons");
}

export function changepasswordaction(password, newpassword) {
  const postData = {
    password,
    newpassword,
  };
  return axiosInstance.get("/password_update", postData);
}

export function formatError(errorResponse, bool) {
  switch (bool) {
    case true:
      //return 'Email already exists';
      swal("Woohoo!", errorResponse, "success");
      break;
    case false:
      //return 'Email not found';
      swal("Oops", errorResponse, "error", { button: "Try Again!" });
      break;

    default:
      return "";
  }
}

export function swalalert(errorResponse, bool) {
  switch (bool) {
    case true:
      //return 'Email already exists';
      swal("Hooray !", errorResponse, "success");
      break;
    case false:
      //return 'Email already exists';
      swal("Oops", errorResponse, "error");
      break;

    default:
  }
}
