import React, { useState, useEffect } from "react";
import {
  rmaInitiateAction_agent,
  GetRMAReasonsAction_agent,
  SubmitRMAAction_agent,
  searchimeilist_agent,
  exportImeilist_agent,
  getstatuslist,
} from "../../../store/actions/DeviceActions";
import axiosInstance from "../../../services/AxiosAdmin";
import { connect, useDispatch } from "react-redux";
import { ExclamationCircleOutlined, UploadOutlined,MinusCircleFilled,CloseCircleOutlined} from "@ant-design/icons";
import _ from "lodash";
import {
  Drawer,
  Space,
  Input,
  Col,
  Form,
  Row,
  Select,
  Button,
  Table,
  Modal,
  message,
  Empty,
  Spin,
  PlusOutlined,
} from "antd";
const { Option } = Select;
const { confirm } = Modal;

const InitiateRMA = (props) => {
  //console.log(props.device);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [imei, setimei] = useState([]);
  const [IMEIOpen, setIMEIOpen] = useState(false);
  const [SelectIMEIOpen, setSelectIMEIOpen] = useState(false);
  const [form] = Form.useForm();
  const [rmaReasons, setRmaReasons] = useState([]);
  const [data, setData] = useState([]);
  const [fulldata, setFulldata] = useState([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnloading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [keyword, setkeyword] = useState("");
  const [postData, setPostData] = useState("");
  const [managestatus,setManagerstatus] = useState([]);

  const [selectedsorcestatus, setSelectedsorcestatus] = useState([]);


  const [imeiList, setImeiList] = useState([]);

  const [itemsimei, setItemimei] = useState([]);

  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);
  const [Corton, setCorton] = useState("");
  const [imEi, setimEi] = useState("");


  const dispatch = useDispatch();
  useEffect(() => {
   
    fetchData({
      // url: urlString ?? "",
      results: 10,
      page: 1,
    });
    dispatch(GetRMAReasonsAction_agent())
      .then((res) => {
        // console.log("Reson",res)
        setRmaReasons(res.data);
      })
      .catch((ex) => {
        console.log(`error fetching RMA reasons`, ex);
      });
      dispatch(getstatuslist()).then((res) => {
        console.log(res.data);
       setManagerstatus(res.data);
      });
  },[]);

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = () => {
    // setLoading(true);
    // dispatch(rmaInitiateAction(getRandomuserParams(tableParams)))
    //   .then((res) => {
    //     // console.log(res);

    //     setData(res.data.data.data);
    //     setFulldata(prevState => [...new Set([...prevState, ...res.data.data.data])]);
    //     setTableParams({
    //       ...tableParams,
    //       pagination: {
    //         ...tableParams.pagination,
    //         total: res.data.data.total,
    //         // 200 is mock data, you should read it from server
    //         // total: data.totalCount,
    //       },
    //     });
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // setItemsrow(JSON.parse(localStorage.getItem("items")));
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };

  const renderReasons = () => {
    return _.map(rmaReasons, (reason, index) => {
      return (
        <Option value={reason.id} key={index}>
          {reason.name}
        </Option>
      );
    });
  };

  const columns = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      title: "Office",
      dataIndex: "office_name",
      key: "office_name",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      title: "Age (Days)",
      dataIndex: "device_age",
      key: "device_age",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      dataIndex: "status_name",
      key: "status_name",
      title: "status",
      width: 200,
      // sortable: false,
      headerAlign: "center",
      // headerClassName: "bold-text",
      align: "center",
    },
    {
      dataIndex: "carton",
      key: "carton",
      title: "Carton Number",
      // headerClassName: "bold-text",
      width: 150,
      fixed: "right",
      headerAlign: "center",
      align: "center",
    },
  ];

 
  const showDrawer = () => {
    setIMEIOpen(true);
    setImeiList(itemsimei);
    console.log("ITEAM IMEI",itemsimei)
    itemsimei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`reason1_${index}`]: "",
        [`reason2_${index}`]: "",
        [`notes_${index}`]: "",
      });
      // return tempRmaList.push({ [`imei_${index}`]: imei_item, 'reason1': "", 'reason2': "", "notes": "" })
    });
    // setRmaList(tempRmaList);
  };
  const AddonClose = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure you want to cancel ?",
      // content: "You want to cancel initiating RMA?",
      onOk: () => {
        setSelectIMEIOpen(false);
        
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onClose = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure you want to cancel initiating the RMA?",
      // content: "You want to cancel initiating RMA?",
      onOk: () => {
        setIMEIOpen(false);
        setImeiList([]);
        setItemimei([]);
        setimei([]);
        setHasSelected(false);
        setSelectedRowKeys([]);
        resetform();
        
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const resetform = () => {
    form.resetFields();
    itemsimei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`reason1_${index}`]: "",
        [`reason2_${index}`]: "",
        [`notes_${index}`]: "",
      });
      // return tempRmaList.push({ [`imei_${index}`]: imei_item, 'reason1': "", 'reason2': "", "notes": "" })
    });
  };

  // const remove = item => {
  //   setImeiList(imeiList.filter(imeiList => imeiList !== item));
  //   console.log("Index",item)
  // };
  const remove = (item,i) => {
    console.log("item",item)
    console.log("index",i)
   
    const updatedImeiList = itemsimei.filter((elem) => elem !== item);
    console.log("imeiList",imeiList)
    console.log("newImeiList",updatedImeiList)

    setItemimei(updatedImeiList)
    setImeiList(updatedImeiList)
    if(updatedImeiList.length === 0){
      setIMEIOpen(false);
        setImeiList([]);
        setItemimei([]);
        setimei([]);
        setHasSelected(false);
        setSelectedRowKeys([]);
        resetform();
    }
    updatedImeiList?.map((item, index) => {
      return form.setFieldsValue({ [`imei_${index}`]: item });
    });

    // const updted = imeiList.filter((elem)=> elem !== item)
    // //  newArrlist = updatedImeiList
    // setImeiList(updted);
    // setItemimei(updatedImeiList)
    // console.log("updated : ",updatedImeiList)
    // console.log(imeiList)
    // console.log("item removed : ", item);
  };
  // const remove = (imei_item) => {
  //   const updatedImeiList = [...imeiList];
  //   updatedImeiList.splice(imei_item, 1);
  //   setImeiList(updatedImeiList);
  //   console.log("Index", imei_item);
  // };

  const onFinish = (values) => {
   console.log("itemsimei",itemsimei)
   console.log("imeiList",imeiList)
    setbtnloading(true);
    var imei_list = [];
    imeiList.map((item, index) => {
      return imei_list.push({
        imei: values[`imei_${index}`],
        reason: values[`reason1_${index}`],
        reason_two: values[`reason2_${index}`],
        notes: values[`notes_${index}`],
      });
    });
    
    dispatch(SubmitRMAAction_agent({ imei_list: imei_list }))

      .then((res) => {
        
        searchonboardingreportRE()
        console.log(`response of RMA submit`, res);
        setIMEIOpen(false);
        setSelectedRowKeys([]);
        setimei([]);
        setImeiList([]);
        setItemimei([]);
        fetchData({
          results: 10,
          page: 1,
        });
        localStorage.removeItem("sitems");
        localStorage.removeItem("sitemsimei");
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
        
        setbtnloading(false);
       
      })
      .catch((err) => {
        //setDropzoneKey(dropzoneKey + 1);
        setIMEIOpen(false);
        setSelectedRowKeys([]);
        setimei([]);
        setItemimei([]);
        setImeiList([]);
        setbtnloading(false);
      });
  };

  const onFinishFailed = (values) => {
    console.log("Failed:", values);
  };

  

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    // console.log("IMEI COUNT Number",newSelectedRowKeys.length)
    setSelectedRowKeys(newSelectedRowKeys);
    var selrowimei = [];
    newSelectedRowKeys.map((selectedRow) => {
      var index = fulldata.findIndex((res) => res.id === selectedRow);
      if (index >= 0) {
        selrowimei.push(fulldata[index].imei);
      }
      return selrowimei;
    });
    console.log(selrowimei);
    setItemimei(selrowimei);
    setHasSelected(selrowimei.length > 0);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const clearSearch = () => {
    setkeyword("");
    fetchData();
    setselactedItems([]);
    setSelectedsorcestatus([]);
    setimEi("");
    setCorton("");
    setData("")
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };
  const searchonboardingreportRE = () => {
    setLoading(true);
    setbtnloading(true);
    
    dispatch(searchimeilist_agent(postData))
      .then((res) => {
        // console.log(res.data.data.data);
        setData(res.data.data.data);
        setFulldata(prevState => [...new Set([...prevState, ...res.data.data.data])]);
        setLoading(false);
        setbtnloading(false);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
        setLoading(false);
        setbtnloading(false);
      });
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnloading(true);
    var search_text = keyword;
    var office_id = selectedItems.key;
    var carton = Corton;
    var imei = imEi;
    var status = selectedsorcestatus;
    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    // console.log(reset);
    const obj = {
      search_text: search_text,
      page: page,
      office_id,
      carton,
      imei,
      status,
    };
    setPostData(obj)
    dispatch(searchimeilist_agent(obj))
      .then((res) => {
        // console.log(res.data.data.data);
        setData(res.data.data.data);
        setFulldata(prevState => [...new Set([...prevState, ...res.data.data.data])]);
        setLoading(false);
        setbtnloading(false);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
        setLoading(false);
        setbtnloading(false);
      });
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var search_text = keyword;
    var office_id = selectedItems.key;
    var carton = Corton;
    var imei = imEi;
    var status = selectedsorcestatus;
    const obj = {
      search_text: search_text,
      office_id,
      carton,
      imei,
      status,
    };
    // console.log(obj);
    dispatch(exportImeilist_agent(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 2) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/office_based_on_retailer?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onchangeorderfilter = (selectedsorcestatus) => {
    setSelectedsorcestatus(selectedsorcestatus);
  };
  const renderorderstatus = ()=> {
    const hiddenStatusIds = [0,8, 10, 11,13,14];
    const filteredStatus = managestatus.filter(master => !hiddenStatusIds.includes(master.id) );
    return filteredStatus.map((master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>Initiate RMA </strong> */} Initiate RMA{" "}
              {tableParams.pagination.total &&
                `(${tableParams.pagination.total})`}
            </h4>
            <div className="d-flex align-items-center text-right">
              <div className="px-3">
                <Button
                  type="primary"
                  className="mr5"
                  loading={loadingEPbtn}
                  onClick={getexportbtn}
                >
                  <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
                </Button>
              </div>
              {/* <div className="align-items-center me-3">
                  <Button
                    type="primary"
                    onClick={() => showDrawerAdd()}
                    className="ml 5"
                  >
                    Add RMA
                  </Button>
              </div> */}
              <div className="float-end ">
                {itemsimei.length !== 0 && (
                  <Button
                    type="primary"
                    onClick={() => showDrawer()}
                    className="mr5"
                  >
                    Initiate RMA {" "}
                    {itemsimei.length &&
                `(${itemsimei.length})`}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mt-2" style={{ padding: "15px" }}>
             
              <div className="col-sm-12 col-md-4 mb-3">
                <label for="exampleFormControlInput1">Search by Offices</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Type 3 characters to search..!"
                  notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                  filterOption={false}
                  onSearch={fetchUser}
                  defaultActiveFirstOption={false}
                  value={selectedItems}
                  onChange={onchangeRole}
                >
                  {fdata?.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
                </div>

            <div className="col-sm-12 col-md-4">
              <label>Search by Carton</label>
              <Input
                placeholder="Search by Carton"
                value={Corton}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) => setCorton(e.target.value)}
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <label>Search by IMEI</label>
              <Input
                placeholder="Search by IMEI"
                value={imEi}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) => setimEi(e.target.value)}
                style={{ width: "100%", display: "block" }}
              />
            </div>  
              {/* <div className="col-sm-12 col-md-4">
              <label>Search by keyword</label>
                <Input
                  placeholder="Search by keyword"
                  value={keyword}
                  onChange={(e) => setkeyword(e.target.value)}
                />
              </div> */}
               <div className="col-sm-12 col-md-4">
                    <label for="exampleFormControlInput1">
                      Select Status
                    </label>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select RMA status"
                      value={selectedsorcestatus}
                      onChange={onchangeorderfilter}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {renderorderstatus()}
                    </Select>
                  </div>
              <div className="col-6 mt-6">
                <Button
                  type="primary"
                  className="mr5"
                  loading={btnloading}
                  onClick={() => searchonboardingreport("reset", "")}
                >
                  Search
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  type="default"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </div>
            </div>

            <div className="bg-white padd0">
              <Table
                columns={columns}
                dataSource={data}
                scroll={{ x: true }}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                // size="small"
              />
            </div>
            {/* <DataGrid
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                    }}
                    autoHeight
                    disableColumnMenu
                    getRowId={(row) => row.imei}
                    rows={data}
                    className={classes.grid}
                    columns={columns}
                    pageSize={pagesize}
                    rowsPerPageOptions={[pagesize]}
                    disableSelectionOnClick
                    checkboxSelection
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRows = data.filter((row) =>
                        selectedIDs.has(row.imei)
                      );
                      onRowsSelectionHandler(ids, selectedRows);
                      console.log(ids, selectedRows);
                    }}
                  // onSelectionModelChange={(ids) =>
                  //   onRowsSelectionHandler(ids)
                  // }
                  /> */}
          </div>
        </div>
      </div>
      <Drawer
        width={680}
        onClose={onClose}
        open={IMEIOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          name="basic"
          layout="vertical"
        >
          <>
            {imeiList.map((item, index) => {
            
              return (
                < div key={index}>
                  {/* {console.log("item",item,index)} */}
                      <div style={{justifyContent:"end"}}>
                          <CloseCircleOutlined  onClick={() => remove(item,index)} 
                          style={{ fontSize: '20px' ,
                          justifyItems:"right", }}
                          title="Cancel IMEI" />
                      </div>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name={`imei_${index}`}
                        label={`IMEI ${index + 1}`}
                        // key={index}
                        // value={item}
                        rules={[
                          {
                            required: true,
                            message: "Please input imei",
                          },
                        ]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Reason 1"
                        name={`reason1_${index}`}
                        rules={[
                          { required: true, message: "Please select reason" },
                        ]}
                        
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Reason"
                          // onChange={reason1OnChange}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {renderReasons()}
                          
                         
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Reason 2"
                        name={`reason2_${index}`}
                        rules={[
                          { required: false, message: "Please select reason" },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Reason"
                          // onChange={reason2OnChange}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {renderReasons()}
                         
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Comment"
                        name={`notes_${index}`}
                        rules={[
                          {
                            required: false,
                            // pattern: new RegExp(/^[0-9]+$/),
                            message: "Please input comment",
                          },
                        ]}
                        
                      >
                        <Input.TextArea rows={2} placeholder="Enter comment" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Space>
              <Button loading={btnloading} type="primary" htmlType="submit">
                Submit
              </Button>
              <Button type="dashed" onClick={resetform}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>

      <Drawer
        width={600}
        onClose={AddonClose}
        open={SelectIMEIOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >

      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    rmaDevices: state.devicelist.rmaDevices,
  };
};

export default connect(mapStateToProps)(InitiateRMA);
