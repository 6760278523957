import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
// import {
//   get_announcement_check_new,
//   update_as_viewed,
// } from "../../../store/actions/assetAction";
import { 
  get_announcement_check_new,
  update_as_viewed,
} from "../src/store/actions/DeviceActions";
const { confirm } = Modal;
const Announcement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(get_announcement_check_new())
      .then((res) => {
        if (res.data.view_flag === 1) {
          confirm({
            title: "IMPORTANT ANNOUNCEMENTS",
            content:
              "Stay updated with the latest announcements. Click on the button below to view the latest announcements.",
            okText: "View More",
            cancelText: "Close",
            cancelButtonProps: {
              disabled: true,
            },
            onOk() {
              dispatch(update_as_viewed())
                .then((res) => {
                    history.push("/announcement");
                })
                .catch((err) => {
                  console.log(err);
                  history.push("/announcement");
                });
            },
            onCancel() {
              window.location.reload();
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, history]);

  return <></>;
};

export default Announcement;
