import {
  GET_DEVICE_ACTION,
  ACCEPT_DEVICE_ACTION,
  INVENTORY_DEVICE_ACTION,
  TRANSFER_DEVICE_ACTION,
  PICKUP_DEVICE_ACTION,
  SCAN_DEVICE_ACTION,
  GET_DEVICE_TRANSACTION,
  DOCUMENT_GET_ACTION,
  RMA_GET_ACTION,
} from "../actions/DeviceTypes";

const initialState = {
  devices: [],
  Accept: [],
  inventory: [],
  transfer: [],
  scandevice: [],
  pickupdevice: [],
  transaction: [],
  documents: [],
  rmareport: [],
};

export default function DeviceReducer(state = initialState, actions) {
  if (actions.type === GET_DEVICE_ACTION) {
    return {
      ...state,
      devices: actions.payload,
    };
  }
  if (actions.type === ACCEPT_DEVICE_ACTION) {
    return {
      ...state,
      Accept: actions.payload,
    };
  }
  if (actions.type === INVENTORY_DEVICE_ACTION) {
    return {
      ...state,
      inventory: actions.payload,
    };
  }
  if (actions.type === TRANSFER_DEVICE_ACTION) {
    return {
      ...state,
      transfer: actions.payload,
    };
  }
  if (actions.type === PICKUP_DEVICE_ACTION) {
    return {
      ...state,
      pcikupdevice: actions.payload,
    };
  }
  if (actions.type === SCAN_DEVICE_ACTION) {
    return {
      ...state,
      scandevice: actions.payload,
    };
  }
  if (actions.type === GET_DEVICE_TRANSACTION) {
    return {
      ...state,
      transaction: actions.payload,
    };
  }
  if (actions.type === DOCUMENT_GET_ACTION) {
    return {
      ...state,
      documents: actions.payload,
    };
  }
  if (actions.type === RMA_GET_ACTION) {
    return {
      ...state,
      rmareport: actions.payload,
    };
  }
  return state;
}
