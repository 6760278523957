import {
  createPost,
  getPosts,
  deletePost,
  updatePost,
  getHomeStat,
} from "../../services/PostsService";
import {
  CONFIRMED_CREATE_POST_ACTION,
  CONFIRMED_DELETE_POST_ACTION,
  CONFIRMED_EDIT_POST_ACTION,
  CONFIRMED_GET_POSTS,
  CONFIRMED_HOME_ACTIONS,
} from "./PostTypes";

export function deletePostAction(postId, history) {
  return (dispatch, getState) => {
    deletePost(postId).then((response) => {
      dispatch(confirmedDeletePostAction(postId));
      history.push("/postpage");
    });
  };
}

export function confirmedDeletePostAction(postId) {
  return {
    type: CONFIRMED_DELETE_POST_ACTION,
    payload: postId,
  };
}

export function createPostAction(postData, history) {
  return (dispatch, getState) => {
    createPost(postData).then((response) => {
      const singlePost = {
        ...postData,
        id: response.data.name,
      };
      dispatch(confirmedCreatePostAction(singlePost));
      history.push("/postpage");
    });
  };
}

export function getPostsAction() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      getPosts()
        .then((response) => {
          console.log(response);
          // let posts = formatPosts(response.data);
          dispatch(confirmedGetPostsAction(response.data.data));
          resolve(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };
}

export function getHomeStatAction() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      getHomeStat()
        .then((response) => {
          console.log(response);
          // let posts = formatPosts(response.data);
          dispatch(confirmedgetHomeStatAction(response.data.data));
          resolve(response);
        })
        .catch((error) => {
          console.log(error.message);
          reject(error);
        });
    });
  };
}

export function confirmedgetHomeStatAction(posts) {
  return {
    type: CONFIRMED_HOME_ACTIONS,
    payload: posts,
  };
}

export function confirmedCreatePostAction(singlePost) {
  return {
    type: CONFIRMED_CREATE_POST_ACTION,
    payload: singlePost,
  };
}

export function confirmedGetPostsAction(posts) {
  return {
    type: CONFIRMED_GET_POSTS,
    payload: posts,
  };
}

export function confirmedUpdatePostAction(post) {
  return {
    type: CONFIRMED_EDIT_POST_ACTION,
    payload: post,
  };
}

export function updatePostAction(post, history) {
  return (dispatch, getState) => {
    updatePost(post, post.id).then((reponse) => {
      dispatch(confirmedUpdatePostAction(post));
      history.push("/postpage");
    });
  };
}
