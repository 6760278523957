import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getmarketerdoclist,
  downloadagentform,
} from "../../../store/actions/DocumentActions";
import { EditOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";

class RetailerDocuments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      pagination: {},
      loading: true,
      market: [],
      superrole: "",
      retailview: false,
      downloadLoading: false,
      status: "",
      reason: null,
    };
  }

  componentWillMount() {
    this.getmarketer();
  }

  getmarketer() {
    console.log(`in getmarketer`, this.props.auth);
    this.props
      .getmarketerdoclist(this.props.auth.user_id)
      .then((res) => {
        console.log(`getmarketerdoclist`, res);
        console.log(this.props.auth.user_id);
        this.setState({
          documents: res.data.items,
          status: res.data.status,
          reason: res.data.reason,
          loading: false,
        });
      })
      .catch((ex) => {
        console.log(`error loading forms`, ex);
        this.setState({ loading: false });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(filter);
    var status = filter?.status ?? [];
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      selectedstatus: status.toString(),
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.getmarketer();
    }, 100);
  };

  getrefreshbtn = () => {
    this.clearSearch();
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  getexportbtn = () => {
    this.setState({ loading: true });
    this.props
      .office_export()
      .then((res) => {
        this.setState({ loading: false });
        window.open(res.data.url, "_blank");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleDownload = (e) => {
    e.preventDefault();
    const documents = this.state.documents;
    if (documents !== undefined && documents !== null && documents.length > 0) {
      this.setState({ downloadLoading: true });
      this.props
        .downloadagentform(this.props.auth.user_id)
        .then((response) => {
          console.log("download form", response);
          // let responseHtml = response;
          // console.log(responseHtml, "Monitoring");
          //open the new window and write your HTML to it
          // var myWindow = window.open("", "response", "resizable=yes");
          // New tab
          // var myWindow =window.open("", "_blank");
          window.open(response?.data?.url ?? "", "_blank");
          // myWindow.document.write(responseHtml);
          this.setState({ downloadLoading: false });
        })
        .catch((ex) => {
          console.log(`error downloading form`, ex);
          this.setState({ downloadLoading: false });
        });
    }
  };

  // ======================================================

  render() {
    const { documents, status, reason, downloadLoading } = this.state;
    const columns = [
      {
        title: "Document Name",
        dataIndex: "form_name",
        key: "form_name",
        sorter: true,
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        // sorter: true,
      },
      {
        title: "Updated At",
        dataIndex: "updated_at",
        key: "updated_at",
        // sorter: true,
      },
      {
        title: "Status",
        dataIndex: "status_text",
        key: "status_text",
        // sorter: true,
      },
      {
        title: "Action",
        key: "action",
        width: 150,
        fixed: "right",
        render: (text, record) => (
          <div>
            <div>
              <span>
                <Link
                  className="padd5 mr5"
                  style={{ marginRight: 10 }}
                  to="#"
                  // {{ pathname: `https://api.excesswies.com/form/${record?.id}/${this.props.auth.idToken}` }}
                  onClick={() => {
                    if (
                      record.status_text.toLowerCase() === "in progress" ||
                      record.status_text.toLowerCase() === "rejected"
                    ) {
                      console.log(
                        "location",
                        `https://api.excesswies.com/form/${record?.form_id}/${this.props.auth.idToken}`
                      );
                      window.location.href = `https://api.excesswies.com/form/${record?.form_id}/${this.props.auth.idToken}`;
                    }
                  }}
                >
                  <Tooltip title="Fill form">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={
                        <EditOutlined
                          disabled={
                            record.status_text.toLowerCase() !==
                              "in progress" &&
                            record.status_text.toLowerCase() !== "rejected"
                          }
                        />
                      }
                    />
                  </Tooltip>
                </Link>
              </span>
            </div>
          </div>
        ),
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-8">{/* <h4>List Of Documents</h4> */}</div>
            {/* </div> */}
            {status?.toLowerCase() === "accepted" ||
            status?.toLowerCase() === "completed" ||
            status?.toLowerCase() === "rejected" ? (
              // <div className="row padd20 bg-grey">
              <div className="col-sm-4" style={{ justifyContent: "flex-end" }}>
                <Button
                  type="primary"
                  className="login-form-button"
                  loading={downloadLoading}
                  onClick={this.handleDownload}
                  disabled={
                    documents === undefined ||
                    documents === null ||
                    documents.length <= 0
                  }
                >
                  Download Documents
                </Button>
              </div>
            ) : // </div>
            null}
          </div>
          <div className="row bg-grey">
            {documents.length == 0 ? (
              ""
            ) : (
              <div className="col-sm-8 mb-3">
                {/* <h6> */}

                <b>Documents Status: </b>
                <span
                  className={
                    status.toLowerCase() === "in progress"
                      ? "badge badge-warning"
                      : status.toLowerCase() === "completed"
                      ? "badge badge-primary"
                      : status.toLowerCase() === "rejected"
                      ? "badge badge-danger"
                      : "badge badge-success"
                  }
                >
                  {status ?? "NA"}
                </span>
                {/* </h6> */}
              </div>
            )}
          </div>
          {status.toLowerCase() === "rejected" ? (
            <div className="row bg-grey">
              <div className="col-sm-8 mb-4">
                <h6>
                  <b>Rejected reason: </b>
                  <span>{reason ?? "NA"}</span>
                </h6>
              </div>
            </div>
          ) : null}
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={documents}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth.auth,
  };
}

export default connect(mapStateToProps, {
  getmarketerdoclist,
  downloadagentform,
})(RetailerDocuments);
