export const GET_DEVICE_ACTION = "[Device Action] Get Device";
export const ACCEPT_DEVICE_ACTION = "[Device Action] Accept Device";
export const INVENTORY_DEVICE_ACTION = "[Inventory Action] Inventory Device";
export const TRANSFER_DEVICE_ACTION =
  "[Transfer Action] TRANSFER_DEVICE_ACTION";
export const SCAN_DEVICE_ACTION = "[Scan Action] SCAN_DEVICE_ACTION";
export const PICKUP_DEVICE_ACTION = "[Pick Action] PICKUP_DEVICE_ACTION";
export const GET_DEVICE_TRANSACTION =
  "[Device Transaction] GET_DEVICE_TRANSACTION";
export const TOGGLE_MENU_ACTION = "TOGGLE_MENU_ACTION";
export const GET_IMAGE_ACTION = "GET_IMAGE_ACTION";
export const DOCUMENT_GET_ACTION = "DOCUMENT_GET_ACTION";
export const RMA_GET_ACTION = "RMA_GET_ACTION";
