/* eslint-disable react-hooks/exhaustive-deps */
import { Table, message, Button, Select, Input, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  SalesSummaryReport,
  getofficelistname,
  detailed_sales_report_search,
  exportsalesreportlist,
} from "../../../store/actions/DeviceActions";

import { connect, useDispatch } from "react-redux";
import moment from "moment";
import "antd/dist/antd.min.css";
const { RangePicker } = DatePicker;
const SalesReport = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnLoading] = useState(false);
  const [selectedstatus, setselectedstatus] = useState([]);
  const [startValue, setstartValue] = useState("");
  const [endValue, setendValue] = useState("");
  const [keyword, setkeyword] = useState("");
  const [onboarding, setonboarding] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [pickerKey, setPickerKey] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    // {
    //   title: "Agent Promo Code",
    //   dataIndex: "agent_promo_code",
    //   key: "agent_promo_code",
    //   sorter: true,
    // },
    {
      title: "Enrollment Number",
      dataIndex: "enroll_id",
      key: "enroll_id",
      sorter: true,
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      sorter: true,
    },
    {
      title: "Pallet",
      dataIndex: "pallet",
      key: "pallet",
    },
    {
      title: "Carton",
      dataIndex: "carton",
      key: "carton",
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      sorter: true,
    },
    {
      title: "Activation Date",
      dataIndex: "activation_date",
      key: "activation_date",
      sorter: true,
    },

    {
      title: "Received Date",
      dataIndex: "received_date",
      key: "received_date",
      sorter: true,
    },
    {
      title: "Plan Name",
      dataIndex: "plan_name",
      key: "plan_name",
    },
    {
      title: "Device Type",
      dataIndex: "device_type",
      key: "device_type",
    },
  ];
  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = () => {
    setLoading(true);
    dispatch(SalesSummaryReport(getRandomuserParams(tableParams)))
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const onChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setstartValue(dateStrings[0]);
      setendValue(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };
  const onChangeSelect = (value) => {
    setselectedstatus(value);
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    // var sort_by = sortOrder;
    console.log(reset, params);
    var status = selectedstatus.toString();
    var search_text = keyword;
    var sort = params.order === undefined ? "" : params.field;
    var sort_by =
      params.order === "ascend"
        ? "ASC"
        : params.order === undefined
        ? ""
        : "DESC";
    var date_range_start = startValue;
    var date_range_end = endValue;

    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    console.log(reset);

    dispatch(
      detailed_sales_report_search({
        status: status,
        search_text: search_text,
        page: page,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
      })
    )
      .then((res) => {
        console.log(res.data.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
  };

  const clearSearch = () => {
    setPickerKey(prevKey => prevKey + 1);
    setLoading(true);
    setstartValue(null);
    setendValue(null);
    setkeyword("");
    setselectedstatus([]);
    fetchData();
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var search_text = keyword;
    var date_range_start = startValue;
    var date_range_end = endValue;
    const obj = {
      search_text: search_text,
      date_range_start,
      date_range_end,
    };
    console.log(obj);
    dispatch(exportsalesreportlist(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const disabledDate = (current) => {
    // Can not select days after today

    const weekStart = moment().subtract(31, "days");
    const weekEnd = moment().endOf("day");
    return !(weekStart.isSameOrBefore(current) && weekEnd.isAfter(current));
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
            <strong>Detailed Report</strong>
              {/* <strong>Detailed Report{" "}
                {tableParams.pagination.total &&
                  `(${tableParams.pagination.total})`}</strong> */}
            </h4>
            <div className="col-sm-2 text-right">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>

          <div className="row mt-2" style={{ padding: "15px" }}>
            <div className="col">
              <label style={{ font: "caption" }}> Activation Date</label>
              <RangePicker
                  key={pickerKey}
                format="MM-DD-YYYY"
                style={{ width: "100%" }}
                onChange={onChange}
                disabledDate={disabledDate}
              />
            </div>
            <div className="col">
              <label style={{ font: "caption" }}>Search By Keyword</label>
              <Input
                placeholder="Type keyword"
                value={keyword}
                onChange={(e) => setkeyword(e.target.value)}
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col mt-4">
              <Button
                type="primary"
                className="mr5"
                loading={btnloading}
                onClick={() => searchonboardingreport("reset", "")}
              >
                Search
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                type="default"
                onClick={clearSearch}
              >
                Clear
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              scroll={{ x: true }}
              columns={columns}
              rowKey={(record) => record.key}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalesReport;
