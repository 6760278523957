import axiosRootInstance from "../../services/AxiosRootInstance";
import { GET_DOCUMENTS } from "./DocumentTypes";

export function getmarketerdoclist(user_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`user_id`, user_id)
      // resolve({
      //   "data":
      //     [
      //       {
      //         "form_name": "Application 1", "field_1": "Name 1", "field_2": "Designation 1",
      //         "field_3": "Address 1", "field_4": "City 1", "update_at": "Today", "status": "Active"
      //       },
      //       {
      //         "form_name": "Application 2", "field_1": "Name 2", "field_2": "Designation 2",
      //         "field_3": "Address 2", "field_4": "City 2", "update_at": "Today", "status": "Active"
      //       },
      //       {
      //         "form_name": "Application 3", "field_1": "Name 3", "field_2": "Designation 3",
      //         "field_3": "Address 3", "field_4": "City 3", "update_at": "Today", "status": "Active"
      //       },
      //       {
      //         "form_name": "Application 4", "field_1": "Name 4", "field_2": "Designation 4",
      //         "field_3": "Address 4", "field_4": "City 4", "update_at": "Today", "status": "Active"
      //       },
      //     ]
      // })
      axiosRootInstance
        .get(`/api/v1/get_document_list/${user_id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_DOCUMENTS,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Download Agent Forms
export function downloadagentform(user_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`user_id`, user_id)
      // resolve("<html><head><title>My header</title></head><body>Hello World</body></html>")
      // const config = {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   }
      // }
      axiosRootInstance.get(`/api/v1/get_document/${user_id}`)
        .then(response => {
          console.log(`response`, response)
          resolve(response)
        }).catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  }
}
