import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { getDeviceTransaction } from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Transaction = (props) => {
  //console.log(props.device);
  const [pagesize, setpagesize] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDeviceTransaction());
  }, [dispatch]);
  const useStyles = makeStyles({
    grid: {
      fontFamily: "poppins, sans-serif !important",
    },
  });
  const classes = useStyles();

  const columns = [
    {
      field: "created_date",
      headerName: "Date & Time",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "message",
      headerName: "message",
      headerClassName: "bold-text",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 250,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (cellValues) => {
        return (
          <span>
            <Link
              to="#"
              onClick={() => handleclick(cellValues)}
              className="btn btn-primary shadow btn-xs  me-1"
            >
              View Message
            </Link>
          </span>
        );
      },
    },
  ];

  const handleclick = (e) => {
    console.log(e.row);
    setData(e.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              <strong>Message History</strong>
            </h4>
          </div>
          <div className="card-body">
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                    }}
                    autoHeight
                    disableColumnMenu
                    getRowId={(row) => row.id}
                    rows={props.device}
                    className={classes.grid}
                    columns={columns}
                    pageSize={pagesize}
                    rowsPerPageOptions={[pagesize]}
                    disableSelectionOnClick
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{data.created_date}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {data.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    device: state.devicelist.transaction,
  };
};

export default connect(mapStateToProps)(Transaction);
